<template>
    <div class="custom-dropzone">
        <div class="custom-dropzone__card" :class="{ 'has-files': droppedFiles.length > 0 }" @dragover.prevent
            @drop="handleDropEvent">

            <div class="custom-dropzone__text">
                <div>{{ $t("dropzone.drag-and-drop-text") }}</div>
                <div>{{ $t("dropzone.or") }}</div>
                <v-btn outlined @click="selectFilesEvent()">
                    {{ $t("dropzone.select-files-text") }}
                </v-btn>
                <v-file-input id="fileUpload" hide-input multiple prepend-icon="" @change="selectFiles"></v-file-input>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            droppedFiles: []
        }
    },
    computed: {
        getTrimmedFileName() {
            return (fileName) => {
                const maxLength = 20
                if (fileName.length > maxLength) {
                    const trimmedName = fileName.substring(0, maxLength - 3) + '...';
                    return trimmedName
                }
                return fileName
            }
        },
    },
    methods: {
        handleDropEvent(event) {
            event.preventDefault()
            const fileList = event.dataTransfer.files

            if (fileList && fileList.length) {
                this.handleDrop(fileList)
            }
        },
        handleDrop(files) {
            this.droppedFiles = [...this.droppedFiles, ...Array.from(files)]
            this.$emit('files', files)
        },
        getFileURL(file) {
            return URL.createObjectURL(file)
        },
        removeFile(index) {
            this.droppedFiles.splice(index, 1)
        },
        selectFilesEvent() {
            let fileUpload = document.getElementById('fileUpload')
            fileUpload.click();
        },
        selectFiles(fileList) {
            if (fileList && fileList.length) {
                this.handleDrop(fileList)
            }
        }
    }
}
</script>
<style>
.custom-dropzone__card {
    min-height: 200px;
    border: 2px solid #ccc;
    padding: 20px;
    text-align: center;
}

.custom-dropzone__file-item {
    display: flex;
    justify-content: space-between;
}
</style>